


































































import { Component, Vue } from 'vue-property-decorator';
import { UserCredentials } from '@/types/account';
import AuthModule from '@/store/modules/auth';
import AlertModule, { AlertType } from '@/store/modules/alert';

@Component
export default class Login extends Vue {
    // Variable that defines whether is shown
    private showPassword = false;

    // Entered user credentials
    public userCredentials: UserCredentials = {
        email: '',
        password: ''
    }

    /**
     * Calls AuthModule.login and displays an alert if an error occurs
     */
    public login() {
        AuthModule.login(this.userCredentials).catch(response => {
            if (response.status == 'error' && response.message) {
                const errorMessage = this.$t('alerts.error.' + response.message) as string;
                AlertModule.showAlert({ type: AlertType.ERROR, message: errorMessage });
            }
        });
    }
}
